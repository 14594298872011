import React, { ReactNode, useEffect } from 'react'

import Loading from 'src/components/Library/Loading/Loading'
import LiveFeatureContext from 'src/lib/hooks/LiveFeatureFlag/LiveFeatureContext'
import useLiveFeature from 'src/lib/hooks/LiveFeatureFlag/UseLiveFeature'

interface FeatureToggleProps {
  feature: string
  InactiveComponent?: JSX.Element
  children?: ReactNode
}

const FeatureToggle: React.FC<FeatureToggleProps> = ({
  feature,
  InactiveComponent,
  children,
}) => {
  const [featureEnabled] = useLiveFeature(feature)
  const [featureDisableOveride, setFeatureDisableOveride] =
    React.useState(false)
  useEffect(() => {
    // Start the timer
    const timeoutId = setTimeout(() => {
      // Timeout logic
      setFeatureDisableOveride(true)
    }, 5000)

    // Cleanup function to clear the timer if the component is unmounted or value is updated
    return () => {
      clearTimeout(timeoutId)
    }
  }, [featureEnabled])

  return (
    <LiveFeatureContext.Consumer>
      {({ flags }) => {
        const featureEnabledChecker = flags.get(feature)
        if (featureEnabledChecker === undefined && !featureDisableOveride) {
          return (
            <div className="max-h-[calc(100vh-65px)] h-full">
              <Loading />
            </div>
          )
        } else if (featureEnabledChecker?.[0] === true) {
          return <>{children}</>
        } else if (InactiveComponent) {
          return <>{InactiveComponent}</>
        } else {
          return null
        }
      }}
    </LiveFeatureContext.Consumer>
  )
}

export default FeatureToggle
